import React from "react";
import BackButton from "../../main/BackButton";
import Video from "../../main/Video";

const Collection16Video = () => {
  return (
    <>
      <BackButton url="/allvideos" />
      <Video
        url={`https://yuriy-dmytrash19.wistia.com/medias/jcukr0euhq`}
        maxHeight="80%"
      />
    </>
  );
};

export default Collection16Video;
