import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import BackButton from "../main/BackButton";
import Line from "../main/Line";
import classes from "./css/Home.module.css";

const AllVideos = () => {
  const [lastVisited, setLastVisited] = useState(null);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  const handleScroll = () => {
    const currentScrollPosition = window.pageYOffset;
    Cookies.set("scrollPositionVids", currentScrollPosition);
  };
  useEffect(() => {
    const scrollPosition = Cookies.get("scrollPositionVids");
    if (scrollPosition) {
      window.scrollTo(0, parseInt(scrollPosition));
      setLastVisited(parseInt(scrollPosition));
    }
  }, []);

  return (
    <>
      <BackButton url="/home" />
      <Line type="bottom-line">
        <NavLink to="/allvideos/one-of-one" className={classes.navLink}>
          one of one luxury
        </NavLink>
      </Line>
      <Line type="bottom-line">
        <NavLink to="/allvideos/mission-video" className={classes.navLink}>
          mission
        </NavLink>
      </Line>
      <Line type="bottom-line">
        <NavLink to="/allvideos/meaning-video" className={classes.navLink}>
          meaning of wonwonleywon name
        </NavLink>
      </Line>
      <Line type="bottom-line">
        <NavLink to="/allvideos/music-video" className={classes.navLink}>
          music video
        </NavLink>
      </Line>
      <Line type="bottom-line">
        <NavLink to="/allvideos/colection-bag" className={classes.navLink}>
          collection #1. bag. 100% leather. made in italy.
        </NavLink>
      </Line>

      <Line type="bottom-line">
        <NavLink to="/allvideos/collection-2" className={classes.navLink}>
          collection #2. cardholder. 100% leather. made in italy.
        </NavLink>
      </Line>
      <Line type="bottom-line">
        <NavLink to="/allvideos/collection-3" className={classes.navLink}>
          collection #3. bracelet. recycled 14k solid gold.
        </NavLink>
      </Line>
      <Line type="bottom-line">
        <NavLink to="/allvideos/collection-4" className={classes.navLink}>
          collection #4. necklace. recycled 14k solid gold.
        </NavLink>
      </Line>
      <Line type="bottom-line">
        <NavLink to="/allvideos/collection-5" className={classes.navLink}>
          collection #5. beanie. 100% cashmere. with recycled 925 sterling
          silver jewelry logo. made in italy.
        </NavLink>
      </Line>
      <Line type="bottom-line">
        <NavLink to="/allvideos/collection-6" className={classes.navLink}>
          collection #6. scarf. 100% organic cotton.
        </NavLink>
      </Line>
      <Line type="bottom-line">
        <NavLink to="/allvideos/collection-7" className={classes.navLink}>
          collection #7. shoe. 100% leather. made in italy.
        </NavLink>
      </Line>
      <Line type="bottom-line">
        <NavLink to="/allvideos/collection-8" className={classes.navLink}>
          collection #8. dress. 100% silk. with recycled 925 sterling silver
          jewelry logo.
        </NavLink>
      </Line>
      <Line type="bottom-line">
        <NavLink to="/allvideos/collection-9" className={classes.navLink}>
          collection #9. tshirt. 100% organic cotton. with recycled 925 sterling
          silver jewelry logo.
        </NavLink>
      </Line>
      <Line type="bottom-line">
        <NavLink to="/allvideos/collection-10" className={classes.navLink}>
          collection #10. hat. 100% cotton. with recycled 925 sterling silver
          jewelry logo.
        </NavLink>
      </Line>
      <Line type="bottom-line">
        <NavLink to="/allvideos/fashion-show" className={classes.navLink}>
          fashion show - 14k gold
        </NavLink>
      </Line>
      <Line type="bottom-line">
        <NavLink to="/allvideos/collection-1112" className={classes.navLink}>
          collection #11 and #12. bikini top and bottom. with recycled 14k solid
          gold jewelry logo.
        </NavLink>
      </Line>
      <Line type="bottom-line">
        <NavLink to="/allvideos/collection-13" className={classes.navLink}>
          collection #13. blanket. cashmere wool silk. with recycled 14k solid
          gold jewelry logo. made in italy.
        </NavLink>
      </Line>

      <Line type="bottom-line">
        <NavLink to="/allvideos/about-us" className={classes.navLink}>
          about us - we do things different
        </NavLink>
      </Line>

      <Line type="bottom-line">
        <NavLink to="/allvideos/collection-14" className={classes.navLink}>
          collection #14. shoe. 100% leather. with recycled 14k solid gold
          jewelry logo. made in italy.
        </NavLink>
      </Line>
      <Line type="bottom-line">
        <NavLink to="/allvideos/collection-15" className={classes.navLink}>
          collection #15. blanket. cashmere wool silk. with recycled 14k solid
          gold jewelry logo. made in italy.
        </NavLink>
      </Line>
      <Line type="bottom-line">
        <NavLink to="/allvideos/collection-16" className={classes.navLink}>
          collection #16. dress. with recycled 14k solid gold jewelry logo.
        </NavLink>
      </Line>
      <Line type="bottom-line">
        <NavLink to="/allvideos/collection-17" className={classes.navLink}>
          collection #17. hat. 100% cotton. with recycled 14k solid gold jewelry
          logo.
        </NavLink>
      </Line>
      {/* <Line type="bottom-line">
        <NavLink to="/allvideos/collection-18" className={classes.navLink}>
          collection #18. tshirt. 100% organic cotton. with recycled 14k solid
          gold jewelry logo.
        </NavLink>
      </Line> */}

      <Line type="bottom-line" />
      <Line type="bottom-line" />
      <Line type="bottom-line" />
      <Line type="bottom-line" />
      <Line type="bottom-line" />
      <Line type="bottom-line" />
      <div style={{ display: "none" }}>
        {lastVisited && setTimeout(() => window.scrollTo(0, lastVisited), 0)}
      </div>
    </>
  );
};

export default AllVideos;
